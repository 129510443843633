import { AlertProvider, LoadingProvider } from "best-common-react";
import React, { ReactNode } from "react";
import { FooterProvider } from "./FooterContext";
import { RolesProvider } from "./RolesContext";
import { UserProvider } from "./UserContext";

type UnauthenticatedContextsProps = {
  children?: ReactNode;
};

const UnauthenticatedContexts: React.FC<UnauthenticatedContextsProps> = ({ children }) => (
  <LoadingProvider>
    <AlertProvider>
      <FooterProvider>
        <UserProvider>
          <RolesProvider>{children}</RolesProvider>
        </UserProvider>
      </FooterProvider>
    </AlertProvider>
  </LoadingProvider>
);

export default UnauthenticatedContexts;
