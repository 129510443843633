import {
  AlertsFromProvider,
  BCR,
  BCRSuspense,
  ErrorCatcher,
  getLocalStorageItem,
  LoadingFromProvider,
  Navy,
  setLocalStorageItem,
  ThemeType,
  useLoading,
} from "best-common-react";
import React, { lazy, Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import styled from "styled-components";
import Footer from "./components/footer/Footer";
import Navigation from "./components/navigation/Navigation";
import { LocalStorageConstants } from "./constants/LocalStorageConstants";
import { RouteConstants } from "./constants/RouteConstants";
import { TranslationConstants } from "./constants/TranslationConstants";
import { oktaAuth, useUser } from "./contexts/UserContext";

const AuthenticatedApp = lazy(() => import("./modules/AuthenticatedApp"));
const UnauthenticatedApp = lazy(() => import("./modules/UnauthenticatedApp"));

type ContainerProps = {
  loggedIn: boolean;
};

const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
`;

const AppCheck = () => {
  const { loggedIn } = useUser();
  const { t } = useTranslation([TranslationConstants.TRANSLATION]);
  const { setAnimated, setLabel } = useLoading();

  useEffect(() => {
    setAnimated(true);
    setLabel(t("translation:loading"));
  }, [t]);

  useEffect(() => {
    if (!loggedIn) {
      const location = window.location;
      if (
        !!location &&
        !location.pathname.includes(RouteConstants.LOGIN_CALLBACK) &&
        !location.pathname.includes(RouteConstants.INITIATE_LOGIN) &&
        location.pathname !== "" &&
        location.pathname !== "/"
      ) {
        const path = location.pathname + location.search;
        setLocalStorageItem(LocalStorageConstants.ORIGINAL_URI, path);
        oktaAuth.setOriginalUri(path);
      } else {
        const originalUri = getLocalStorageItem<string>(LocalStorageConstants.ORIGINAL_URI);
        oktaAuth.setOriginalUri(originalUri);
      }
    }
  }, [loggedIn]);

  return (
    <Container loggedIn={loggedIn}>
      {loggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      <Footer />
    </Container>
  );
};

const App: React.FC = () => {
  const IplTheme: ThemeType = {
    ...Navy,
    navigation: {
      ...Navy.navigation,
      horizontal: {
        ...Navy.navigation.horizontal,
        rightLogo: {
          height: "34px",
          width: "58px",
          margin: "0 .5rem 0 0",
        },
      },
    },
  };

  return (
    <Suspense fallback={<BCRSuspense />}>
      <BCR theme={IplTheme}>
        <ErrorCatcher appName="IPL">
          <AlertsFromProvider />
          <LoadingFromProvider />
          <BrowserRouter>
            <Navigation />
            <AppCheck />
          </BrowserRouter>
        </ErrorCatcher>
      </BCR>
    </Suspense>
  );
};

export default App;
