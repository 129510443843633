import { Security } from "@okta/okta-react";
import React, { ReactNode } from "react";
import { HelmetProvider } from "react-helmet-async";
import HttpInterceptor from "../api/HttpInterceptor";
import UnauthenticatedContexts from "../contexts/UnauthenticatedContexts";
import { oktaAuth } from "../contexts/UserContext";

type WrapperProviderProps = {
  children?: ReactNode;
};

const WrapperProvider: React.FC<WrapperProviderProps> = ({ children }) => {
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string): Promise<void> => {
    window.location.replace(originalUri);
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <UnauthenticatedContexts>
        <HttpInterceptor>
          <HelmetProvider>{children}</HelmetProvider>
        </HttpInterceptor>
      </UnauthenticatedContexts>
    </Security>
  );
};

export default WrapperProvider;
