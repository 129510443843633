import { Button, Modal } from "best-common-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { TranslationConstants } from "../../constants/TranslationConstants";
import useLanguage from "../../hooks/useLanguage";

type ChangeLanguageModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const ChangeLanguageModal: React.FC<ChangeLanguageModalProps> = ({ open, setOpen }) => {
  const { t, i18n } = useTranslation([TranslationConstants.TRANSLATION, TranslationConstants.NAVIGATION]);
  const { isEs } = useLanguage();
  const toggleLanguage = async () => {
    if (isEs) {
      return i18n.changeLanguage("en");
    } else {
      return i18n.changeLanguage("es");
    }
  };

  const changeLanguage = async () => {
    await toggleLanguage();
    setOpen(false);
  };

  return (
    <Modal show={open}>
      <Modal.Header>{t("navigation:changeLanguage.name")}</Modal.Header>
      <Modal.Body>{t("navigation:changeLanguage.confirmation")}</Modal.Body>
      <Modal.Footer>
        <Button onClick={changeLanguage} className="me-1" variant="primary">
          {t("navigation:changeLanguage.yes")}
        </Button>
        <Button onClick={() => setOpen(false)} variant="secondary">
          {t("navigation:changeLanguage.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeLanguageModal;
