import React from "react";
import FooterImage from "./FooterImage";
import styled from "styled-components";

const FooterContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 50px;
`;

const BottomBar = styled.div`
  position: absolute;
  width: 100%;
  height: 50px;
  bottom: 0;
  background: ${(props) => props.theme.colors["mlb-navy"]};
`;

const AbsolutePositionedFooter: React.FC = () => {
  return (
    <div>
      <FooterContainer>
        <FooterImage />
      </FooterContainer>
      <BottomBar />
    </div>
  );
};

export default AbsolutePositionedFooter;
