export const TranslationConstants = {
  CONSENT_FORM: "consentForm",
  HELP: "help",
  INVITATION: "invitation",
  LOGIN: "login",
  NAVIGATION: "navigation",
  PROSPECT: "prospect",
  PROSPECT_PROFILE: "profile",
  PROSPECT_GRID: "prospectGrid",
  TASKS: "tasks",
  TRANSLATION: "translation",
  TUTORIAL: "tutorial",
  DUPLICATE_INVITATIONS: "duplicateInvitations",
  DUPLICATE_ACCOUNTS: "accounts",
  ADMIN: "admin",
  CLUB_USER_SETTINGS: "clubUserSettings",
};
