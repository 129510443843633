import React, { createContext, ReactNode, useContext, useState } from "react";
type FooterProviderProps = {
  children?: ReactNode;
};

type FooterContextType = {
  show: boolean;
  setShow: (value: boolean) => void;
};

const FooterContext = createContext<FooterContextType | undefined>(undefined);

const FooterProvider: React.FC<FooterProviderProps> = ({ children }) => {
  const [show, setShow] = useState<boolean>(true);

  return (
    <FooterContext.Provider
      value={{
        show,
        setShow,
      }}
    >
      {children}
    </FooterContext.Provider>
  );
};

const useFooter = (): FooterContextType => {
  const userContext = useContext<FooterContextType>(FooterContext);
  if (userContext === undefined) {
    throw new Error(`useFooter must be used within a FooterProvider`);
  }
  return userContext;
};

export { useFooter, FooterProvider };
