import { useBreakpoints } from "best-common-react";
import React from "react";
import FooterMobileImagePng from "../../assets/footer-mobile.png";
import FooterImagePng from "../../assets/footer.png";
import styled from "styled-components";

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  width: 768px;
`;

const FooterImage: React.FC = () => {
  const { isMobile } = useBreakpoints();
  return (
    <FooterContainer>
      <Image
        src={isMobile ? FooterMobileImagePng : FooterImagePng}
        alt="Footer Image"
        style={{ maxWidth: isMobile ? "305px" : "661px", width: "100%" }}
      />
    </FooterContainer>
  );
};

export default FooterImage;
