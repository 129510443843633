import { useBreakpoints } from "best-common-react";
import React from "react";
import { useFooter } from "../../contexts/FooterContext";
import AbsolutePositionedFooter from "./AbsolutePositionedFooter";

const Footer: React.FC = () => {
  const { show } = useFooter();
  const { isMobile } = useBreakpoints();

  if (isMobile) return null;

  return !!show && <AbsolutePositionedFooter />;
};

export default Footer;
