export const LocalStorageConstants = {
  OKTA_TOKEN: "okta-token-storage",
  LAST_CACHE_TIME: "lastCacheTime",
  COUNTRIES: "countries",
  EMAIL_TYPES: "emailAddressTypes",
  NATIONALIDTYPES: "nationalIDTypes",
  PHONE_NUM_TYPES: "phoneNumberTypes",
  PHONE_NUM_COMMUNICATION_TYPES: "phoneNumberCommunicationTypes",
  POSITIONS: "positions",
  PROVINCES: "province",
  STATES: "states",
  BATTING_SIDE: "battingSide",
  ORGANIZATIONS: "organizations",
  THROWING_HANDS: "throwingHands",
  TRAINERS: "trainers",
  MEXICAN_LEAGUE_TEAMS: "mexicanLeagueTeams",
  HEIGHT_UNITS: "heightUnitsOfMeasurement",
  WEIGHT_UNITS: "weightUnitsOfMeasurement",
  ORIGINAL_URI: "ipl-original-uri",
  WAIVER_TYPES: "waiver-types",
  CURRENT_REGISTRATION_PERIOD: "current-registration-period",
  PROSPECT_GRID_FILTERS: "prospect-grid-filters",
  INTL_PROFESSIONAL_LEAGUES: "intlProfessionalLeagues",
  INTL_PROFESSIONAL_TEAMS: "intlProfessionalTeams",
};
