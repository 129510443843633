import { datadogRum } from "@datadog/browser-rum";
import { OktaAuth } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { getUserInfo } from "../api/IPLClient";
import config from "../config";
import { UserDetails } from "../types/User";

type UserProviderProps = {
  children?: ReactNode;
};

type UserContext = {
  userInfo?: UserDetails;
  loggedIn: boolean;
  login: () => void;
  logout: () => void;
  permissionError: boolean;
  loginFunction: SetStateAction<void>;
  setPermissionError: Dispatch<SetStateAction<boolean>>;
  setLoginFunction: Dispatch<SetStateAction<void>>;
};

const UserContext = createContext<UserContext | undefined>(undefined);

const oktaAuth = new OktaAuth(config.oidc);

const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [userInfo, setUserInfo] = useState<UserDetails>();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [permissionError, setPermissionError] = useState<boolean>(false);
  const [loginFunction, setLoginFunction] = useState(null);
  const { authState } = useOktaAuth();

  const login = async (): Promise<void> => {
    const userInfoData: UserDetails = await getUserInfo();
    if (userInfoData) {
      setUserInfo(userInfoData);
      setLoggedIn(true);
    }
  };

  const logout = (): void => {
    localStorage.clear();
    setUserInfo(undefined);
    setLoggedIn(false);
  };

  useEffect(() => {
    if (authState?.isAuthenticated && !loggedIn) {
      login();
    }
  }, [authState]);

  useEffect(() => {
    if (!!userInfo) {
      datadogRum.setUser({
        id: null,
        email: userInfo.username,
        name: `${userInfo.lastName}, ${userInfo.firstName}`,
      });
    } else {
      datadogRum.removeUser();
    }
  }, [userInfo]);

  return (
    <UserContext.Provider
      value={{
        userInfo,
        loggedIn,
        login,
        logout,
        permissionError,
        setPermissionError,
        loginFunction,
        setLoginFunction,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = (): UserContext => {
  const userContext = useContext<UserContext>(UserContext);
  if (userContext === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return userContext;
};

export { UserContext, UserProvider, oktaAuth, useUser };
