import { datadogRum } from "@datadog/browser-rum";
import { EnvironmentType } from "best-common-react";
import React from "react";
import { createRoot } from "react-dom/client";
import "../i18next/i18n";
import App from "./App";
import WrapperProvider from "./modules/WrapperProvider";

// Import Best Common React Styles
import "best-common-react/lib/styles/bcr.css";

const env = window.MLBBest.envVariables.ENV as EnvironmentType;

datadogRum.init({
  applicationId: "677115e7-6703-4c8d-97ca-6d2ddf358515",
  clientToken: "pub6576cbc07af880531cf04ed14215bab5",
  site: "us5.datadoghq.com",
  service: "prospect-link-international-(boc/club)",
  env: env,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 50,
  trackUserInteractions: true,
  defaultPrivacyLevel: "mask-user-input",
});

datadogRum.startSessionReplayRecording();
const container = document.getElementById("frontend-starter-app");
const root = createRoot(container);
root.render(
  <WrapperProvider>
    <App />
  </WrapperProvider>,
);
