export const RouteConstants = {
  LOGIN: "/login",
  LOGIN_CALLBACK: "/login/callback",
  INITIATE_LOGIN: "/initiate/login",
  BASE: "/",
  HOME: "/home",
  PROSPECTS: "/prospects",
  PROSPECTS_PROFILE: "/prospects/:prospectId",
  INVITATIONS: "/invitations",
  DUPLICATE_INVITATIONS: "/duplicateInvitations",
  DUPLICATE_ACCOUNTS: "/duplicateAccounts",
  CLUB_USER_SETTINGS: "/clubUserSettings",
  SETTINGS: "/settings",
  HELP: "/settings/help",
  CONSENT_FORM: "/settings/consentForm",
  CONSENT_FORM_EDIT: "/settings/consentForm/edit/:consentFormId",
  TPP_WAIVER_EDIT: "/settings/tppwaiver/edit/:tppWaiverId",
  TUTORIALS: "/tutorials",
  TASKS: "/tasks",
  EDIT_TASK: "/tasks/edit/:taskId",
  REPORTS: "/reports",
  TRAINERS: "/trainers",
  TRAINER_PROFILE: "/trainers/:trainerId",
  WILDCARD: "*",
};
